/* Login CSS Start */

.portal_loginpage {

  display: flex;
  flex-direction: column;
  align-items: center;

  .portal_loginpage_heading {
    margin: 10px;
    text-align: center;

    @media only screen and (max-width: 1100px) {
      margin-top: 60px;
    }
  }

  .portal_loginpage_text {
    text-align: center;
    align-self: center;

    @media only screen and (max-width: 1100px) {
      width: 100%;
    }
  }

}
/* Login CSS End */
