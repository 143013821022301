.portal_loginpage {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.portal_loginpage .portal_loginpage_heading {
  text-align: center;
  margin: 10px;
}

@media only screen and (width <= 1100px) {
  .portal_loginpage .portal_loginpage_heading {
    margin-top: 60px;
  }
}

.portal_loginpage .portal_loginpage_text {
  text-align: center;
  align-self: center;
}

@media only screen and (width <= 1100px) {
  .portal_loginpage .portal_loginpage_text {
    width: 100%;
  }
}

html {
  width: 100%;
  height: 100%;
  overflow-y: auto !important;
}

body {
  width: 100%;
  height: 100%;
}

body, p, div, a, button {
  color: #000;
}

a {
  text-decoration: none;
}

.cs-main-container-wrapper {
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  display: flex;
}

.mainWrapper {
  flex-direction: column;
  align-self: center;
  gap: 15px;
  width: 100%;
  max-width: 700px;
  display: flex;
}

.portal .portal-greeting-wrapper {
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.portal .portal-greeting-wrapper .portal-greeting-content {
  flex-direction: column;
  width: 50%;
  display: flex;
}

.portal .portal-greeting-wrapper .portal-greeting-content h2 {
  margin-bottom: 0;
  padding: 0;
}

.portal .portal-greeting-wrapper .portal-greeting-content p {
  margin-top: 5px;
  padding: 0;
  font-size: 12px;
}

.portal .portal-main-wrapper {
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.portal .portal-main-wrapper .portal-main-content {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 800px;
  margin-top: 48px;
  margin-bottom: 48px;
  display: flex;
}

@media screen and (width <= 800px) {
  .portal .portal-main-wrapper .portal-main-content {
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-top: 50px;
  }
}

.portal .portal-main-wrapper .portal-main-content .portal-column {
  flex-direction: column;
  gap: 20px;
  width: 100%;
  display: flex;
}

.portal .portal-main-wrapper .portal-main-content .portal-column .portal-column-item {
  flex-direction: column;
  width: 100%;
  margin: 15px 0;
  display: flex;
}

.portal .portal-main-wrapper .portal-main-content .portal-column .portal-column-item.border {
  border: 2px solid #4c338c;
  border-radius: 6px;
  padding: 30px 15px;
}

.portal .portal-main-wrapper .portal-furtherInformation-firstRow p, .portal .portal-main-wrapper .portal-furtherInformation-firstRow a {
  display: inline;
}

.mainPage {
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  margin-top: 50px;
  display: flex;
}

.mainPage.no-mt {
  margin-top: 0;
}

.mainPage form {
  margin-bottom: 15px;
}

.portal_routingpage_wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  display: flex;
}

@media only screen and (width <= 750px) {
  .portal_routingpage_wrapper {
    align-items: unset;
    display: unset;
    flex-direction: unset;
    justify-content: unset;
  }
}
/*# sourceMappingURL=index.238e30f9.css.map */
